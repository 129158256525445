jQuery(($) => {

	//the link that opens the modal video eg <a href="#" data-video="2">play video</a>
	$('[data-video]').click(function (e) {
		e.preventDefault();
		let
						$button = $(this),
						videoId = $button.data('video');
		$('body')
						//show the fullscreen overlay
						.addClass('video-overlay-open')
						// body[data-video-modal-open="<id>"] will show the right video
						.attr('data-video-modal-open', videoId);

		//play the video 
		let vid = $('[data-video-modal="' + videoId + '"]').find('video-js').get(0);
		vid.player.play();

		//pause the fullscreen video if there is one
		$('.video-bg').find('video').each(function () {
			this.pause();
		});
	});

	//off canvas event to close the modal
	$('.video-modal').click(function (e) {
		if ($(e.originalEvent.target).parent().hasClass('video-modal')) {
			modalClose(e);
		}
	})

	// close the modal on X button or esc key
	$('.modal-close').click(modalClose);
	$(document).keyup(function (e) {
		if (e.keyCode === 27) // esc
			modalClose(e)
	});

	function modalClose(e) {
		// closes the modal and overlay and stops the video
		e.preventDefault();
		$('body')
						.removeClass('video-overlay-open')
						.removeAttr('data-video-modal-open');

		//pause the modal videos on modal close
		$('.video-modal')
						.find('video')
						.each(function () {
							var video = $(this).get(0);
							video.pause();
						});

		//resume playing the full screen video if there is one
		$('.video-bg').find('video').each(function () {
			this.play();
		});
	}

	function videoSize() {
		$('.video-modal')
						.find('video')
						.each(function () {
							window.x = this;
							let
											$this = $(this),
											$player = $this.closest('.video-js');
							$player.find('.vjs-control-bar').css('visibility', 'hidden');
							$player.removeAttr('style');

							let
											// the dimensions of the videoJS player
											playerWidth = $player.outerWidth(true),
											playerHeight = $player.outerHeight(true),
											//the dimensions of the video element
											video = $this.get(0),
											videoWidth = video.videoWidth,
											videoHeight = video.videoHeight,
											//aspect ratios
											playerAspect = playerWidth / playerHeight,
											videoAspect = videoWidth / videoHeight;
							if (playerAspect > videoAspect) {
								$player.css('width', (playerHeight * videoAspect) + 'px');
							} else {
								$player.css('height', (playerWidth / videoAspect) + 'px');
							}

							$player.find('.vjs-control-bar').css('visibility', 'visible');

						});
		$('.video-bg')
						.find('video')
						.each(function () {
							let
											$this = $(this),
											$player = $this.closest('.video-js');
							$this.removeAttr('style');

							let
											// the dimensions of the videoJS player
											playerWidth = $player.outerWidth(true),
											playerHeight = $player.outerHeight(true),
											//the dimensions of the video element
											video = $this.get(0),
											videoWidth = video.videoWidth,
											videoHeight = video.videoHeight,
											//aspect ratios
											playerAspect = playerWidth / playerHeight,
											videoAspect = videoWidth / videoHeight;
							if (playerAspect > videoAspect) {
								$this.css({'height': 'auto', 'min-width': '100%'})
							}
						});
	}

	function initVideo(video) {

		if (typeof video.playerId !== 'undefined') {
			//video is already initialized. bail.
			return;
		}

		const player = videojs(video, {
			/* this makes the video work in Edge somehow */
			html5: {hls: {overrideNative: true}}
		});

		player.on(['resize', 'durationchange', 'play'], videoSize);

	}

	$(window).resize(videoSize);

	$('video-js').each(function () {
		initVideo(this);
	});


});
