import videojs from 'video.js';
import Icons from 'uikit/dist/js/uikit-icons'; // eslint-disable-line
import TweenMax from 'gsap/TweenMax';
import { TimelineMax, CSSPlugin } from 'gsap/all';
import { CountUp } from 'countup.js';
import './_video-player';

// Make Video.js available to child theme
window.videojs = videojs;

// Make UIKit icons available to the window.
window.UIkit.use(Icons);


// Make GSAP available to Child theme
window.TweenMax = TweenMax;
window.TimelineMax = TimelineMax;
window.CSSPlugin = CSSPlugin;

